body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  /* width: 100vw;
  height: 100vh; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 0;
}

.modal-content a {
  font-weight: 800;
}

.heading,
.description,
.link {
  margin: 1rem 0;
}

a p.link {
  font-weight: 500;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  width: 350px;
  height: auto;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

@media only screen and (min-width: 1000px) {
  .modal-content {
    width: 600px;
    height: auto;
  }
}
