/* * {
  outline: 1px solid rgb(255, 53, 53) !important;
} */

.inative {
  pointer-events: auto;
}

.header {
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Navbar_image {
  width: 120px;
  height: auto;
  padding: 25px;
}

nav {
  display: flex;
  flex-direction: row;
}

nav a {
  margin: 0 3.5rem;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 1.1rem;
  color: black;
  text-decoration: none;
}

nav a:hover {
  transition: 0.1s;
  border-bottom: 2px solid black;
}

.nav-btn {
  display: none;
  visibility: hidden;
  opacity: 0;
}

@media only screen and (max-width: 768px) {
  .inactive {
    pointer-events: none;
    overflow: hidden;
  }

  .inactive nav {
    pointer-events: auto;
  }

  nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: rgba(241, 239, 239, 0.473);
    padding: 3rem 0rem;
    gap: 1.5rem;
    background-color: rgba(241, 239, 239, 0.473);
    backdrop-filter: blur(10px);
    transition: 0.5s;
    transform: translateX(100%);
  }

  nav a {
    margin: 0 2.5rem;
  }

  .nav_popout {
    transform: none; /* reset any previous transformations applied to the nav, thus making nav expanded again*/
  }

  .nav-btn {
    -webkit-tap-highlight-color: transparent;
    display: block;
    visibility: visible;
    opacity: 1;
    margin-right: 1rem;
    cursor: pointer;
    font-size: 1.5rem;
    background: transparent;
    color: black;
    border: none;
    outline: none;
  }

  .nav-close-btn {
    margin-right: 0.5rem;
    position: absolute;
    top: 25px;
    right: 20px;
  }
}

@media only screen and (max-width: 500px) {
  nav {
    width: 60%;
  }
}
