*,
*::after,
*::before {
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: "Poppins", sans-serif;
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  min-height: 100vh;

  background-color: #ffffff;
  opacity: 1;
  background-image: linear-gradient(#f9f9f9 3.1px, transparent 3.1px),
    linear-gradient(to right, #f9f9f9 3.1px, #ffffff 3.1px);
  background-size: 62px 62px;
}

a {
  color: rgb(0, 7, 56);
  text-decoration: none;
}
