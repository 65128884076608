.topic-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  gap: 0.93rem;
  background: #ffffff;
  box-shadow: 8px 8px 28px rgb(0 0 0 / 12%);
  border-radius: 0.8rem;
  max-width: 20rem;
  margin: 0 auto;
  width: 100%;
}

.img-wrapper {
  width: 100%;
  height: 15.625rem;
  border-radius: 0.7rem;
  overflow: hidden;
}

.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topic-card h2 {
  font-weight: 500;
  color: rgb(48, 46, 46);
  font-size: 1.875rem;
  line-height: 1.3;
}

.topic-card p {
  font-weight: 300;
  font-size: 1rem;
  color: rgb(66, 64, 64);
  line-height: 1.3;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 6.25rem;
  font-weight: normal;
  /* white-space: nowrap; */
}

.topic-card a {
  color: rgb(66, 64, 64);
  display: flex;
  align-items: center;
  gap: 0.625rem;
  position: relative;
  cursor: pointer;
}

.link-text {
  color: black;
  font-weight: light;
  font-size: 1rem;
  line-height: 1.3;
}

.svg-image {
  margin-left: 0.5rem;
}

.link-text:hover {
  cursor: pointer;
}

@media only screen and (min-width: 1000px) {
  .topic-card {
    margin: 1rem;
  }
}
